import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { courses } from "./constants/Courses"
import {
  Button,
  Grid,
  Hidden,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core"
import customTheme from "../gatsby-theme-material-ui-top-layout/customTheme"

const ContentPreview = ({ direction, isButton, content, data }) => {
  const useStyles = makeStyles(theme => ({
    cardContainer: {
      height: "100%",
      maxWidth: direction === "column" ? "40rem" : "100%",
      margin: "0 auto",
      marginBottom: "2rem",
      paddingBottom: "2rem",
      paddingTop: "2rem",
      cursor: "pointer",
      transition: "all .2s",
      "&:hover": {
        // paddingTop: "0",
        // paddingBottom: "2.5",
        // transform: "scale(.9)",
        boxShadow:
          " rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px;",
      },
      [theme.breakpoints.down("md")]: {
        paddingTop: "2rem",
        maxWidth: "100%",
      },
    },
    cardImage: {
      marginBottom: "2rem",
      width: "100%",
    },
    cardInfo: {
      // padding: "0 .5rem",
      padding: "0 1rem",
      [theme.breakpoints.down("sm")]: {
        padding: "0 8rem",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "0 1rem",
      },
    },
    cardTitle: {
      fontSize: "2.4rem",
      fontWeight: "500",
      [theme.breakpoints.down("md")]: {
        fontSize: "2rem",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.6rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.4rem",
      },
    },
    cardDescription: {
      fontSize: "1.8rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "1.6rem",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.2rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
      },
    },
    cardButton: {
      fontSize: "1.8rem",
      justifySelf: "flex-end",
      marginTop: direction === "row" ? "-6.8rem" : "0",
      width: direction === "column" ? "80%" : "inherit",
      [theme.breakpoints.down("md")]: {
        alignSelf: "center",
        fontSize: "1.6rem",
        marginTop: "0",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.2rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
      },
      "&:hover": {
        color: customTheme.palette.primary.main,
      },
    },
  }))

  const classes = useStyles()
  //   const matches600 = useMediaQuery("(max-width: 600px)")
  const matches960 = useMediaQuery("(max-width: 960px)")

  let contentArray = []

  switch (content) {
    case "services":
      break

    case "courses":
      contentArray = [...courses]
      contentArray.forEach((course, index) => {
        course.image = getImage(data.allFile.nodes[index].courseImage[0])
        return course
      })
      break

    case "blogPosts":
      contentArray = data.posts.nodes
      contentArray.forEach(post => {
        post.image = getImage(post.image)
        return post
      })
      break

    default:
      return null
  }

  return contentArray.map(contentItem => {
    return (
      <Grid item md key={`${contentItem.title}${contentItem.summary}`}>
        <Grid
          container
          direction={direction}
          className={classes.cardContainer}
          justify={matches960 ? "center" : "flex-start"}
        >
          {/* Card image */}
          <Hidden xsDown>
            <Grid
              item
              container
              justify={matches960 ? "center" : undefined}
              md={direction === "row" ? 4 : undefined}
            >
              <GatsbyImage
                image={contentItem.image}
                alt={contentItem.alt ? content.alt : "blog image"}
                className={classes.cardImage}
              />
            </Grid>
          </Hidden>
          {/* Card Info */}
          <Grid
            item
            container
            direction="column"
            justify="space-between"
            className={classes.cardInfo}
            md
          >
            <Grid item>
              <Grid item>
                <Typography
                  align={matches960 ? "center" : "right"}
                  className={classes.cardTitle}
                >
                  {contentItem.title}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  align={matches960 ? "center" : "right"}
                  paragraph
                  className={classes.cardDescription}
                >
                  {content === "blogPosts"
                    ? contentItem.summary.summary
                    : contentItem.summary}
                </Typography>
              </Grid>
            </Grid>
            {isButton && (
              <Grid
                item
                align={
                  direction === "column"
                    ? "center"
                    : matches960
                    ? "center"
                    : "flex-start"
                }
              >
                <Button
                  variant="outlined"
                  color="primary"
                  component={Link}
                  to={
                    content === "courses"
                      ? `/training_courses/${contentItem.link}`
                      : `/blog/${contentItem.slug}`
                  }
                  className={classes.cardButton}
                >
                  {content === "courses" ? "المزيد حول الدورة" : "قراءة المقال"}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    )
  })
}

export default ContentPreview
