import React from "react"
import Layout from "../components/Layout"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  Button,
  Grid,
  makeStyles,
  Typography,
  CssBaseline,
  Hidden,
} from "@material-ui/core"
import theme from "../gatsby-theme-material-ui-top-layout/customTheme"
import ContentPreview from "../components/ContentPreview"

const useStyles = makeStyles({
  sectionSeperator: {
    marginBottom: "8rem",
  },
  sectionSeperatorShadowed: {
    borderRadius: "100%",
    margin: "6rem auto 8rem",
    boxShadow: "0 14px 0px -6px #660000",
    width: "40%",
    height: "1.3rem",
  },
  header: {
    // backgroundColor: theme.palette.primary.main,
    marginTop: "-32.5rem",
  },
  courseHero: {
    marginTop: "-10rem",
  },
  title: {
    fontWeight: "bold",
    fontSize: "3.2rem",
    textAlign: "right",
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down("xs")]: {
      fontSize: "2rem",
    },
  },
  heroText: {
    fontSize: "1.6rem",
    textAlign: "right",
    color: theme.palette.primary.contrastText,
  },
  normalText: {
    fontSize: "1.6rem",
    textAlign: "right",
  },
  headingText: {
    fontSize: "2rem",
    fontWeight: "bold",
    textAlign: "right",
  },
  image: {
    width: "100%",
    height: "37.2rem",
  },
  imageContainer: {
    position: "relative",
    marginRight: "1rem",
  },
  imageShadow: {
    position: "absolute",
    zIndex: 1,
    top: "0",
    width: "100%",
    height: "100%",
    background: "linear-gradient(to right, rgba(0,0,0,.5), rgba(0,0,0,0))",
  },
  ctaStrip: {
    position: "absolute",
    zIndex: 2,
    top: "22rem",
    width: "100%",
    padding: "1rem",
    borderRadius: "3px",
    backgroundColor: theme.palette.primary.dark,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    fontSize: "2rem",
    padding: "2rem 8rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.4rem",
    },
    "&:hover": {
      color: "#fff",
    },
  },
  buttonMore: {
    fontSize: "2rem",
    margin: "2rem 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.4rem",
    },
    "&:hover": {
      color: "#fff",
    },
  },
  littleRedSquare: {
    backgroundColor: "rgba(195, 0, 0, 1)",
    width: "1.5rem",
    height: "1.5rem",
    display: "inline-block",
    marginLeft: "1rem",
    borderRadius: "3px",
    [theme.breakpoints.down("xs")]: {
      width: "1rem",
      height: "1rem",
    },
  },
})

const Course = ({ pageContext: { courseInfo }, data }) => {
  const {
    title,
    alt,
    contains,
    summary,
    // goals,
    days,
    targets,
    certificate,
  } = courseInfo

  const pageMeta = {
    title: courseInfo.title,
    description: courseInfo.summary,
    keywords: courseInfo.title.split(" "),
  }

  const classes = useStyles()

  const containsList = contains.map((item, index) => {
    return (
      <Typography className={classes.heroText} key={`${item} ${index}`}>
        {" "}
        - {item}
      </Typography>
    )
  })

  const schedule = days.map((day, index) => {
    return (
      <Typography className={classes.normalText} key={`${day} ${index}`}>
        {day.toString()}
      </Typography>
    )
  })

  const targetAudience = targets.map((target, index) => {
    return (
      <Typography className={classes.normalText} key={`${target} ${index}`}>
        {target.toString()}
      </Typography>
    )
  })
  const redBackground = getImage(data.redBackground.childrenImageSharp[0])

  return (
    <Layout pageMeta={pageMeta} background={redBackground}>
      <CssBaseline />
      <Grid container direction="column">
        <Grid item>
          {/* Course Hero */}
          <Grid container className={classes.header}>
            <Grid item className={classes.courseHero} sm>
              <Typography className={classes.title}>{title}</Typography>
              <Typography>{containsList}</Typography>
            </Grid>
            <Hidden smDown>
              <Grid item className={classes.imageContainer} sm>
                <div className={classes.imageShadow} />
                <GatsbyImage
                  image={getImage(data.file.childrenImageSharp[0])}
                  alt={alt}
                  className={classes.image}
                />
                <div className={classes.ctaStrip}>
                  <Button
                    color="primary"
                    variant="contained"
                    component={Link}
                    to="/contact"
                    className={classes.button}
                  >
                    لحجز الدورة
                  </Button>
                </div>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
        {/* Rest of the details */}
        <Grid item>
          <div className={classes.sectionSeperator} />
          <Grid container direction="column" spacing={4}>
            {/* 1 */}
            <Grid item>
              <Typography className={classes.headingText} paragraph>
                <span className={classes.littleRedSquare} />
                ماذا ستتعلم من الدورة؟
              </Typography>
              <Typography className={classes.normalText}>{summary}</Typography>
            </Grid>
            {/* 2 */}
            <Grid item>
              <Typography className={classes.headingText} paragraph>
                <span className={classes.littleRedSquare} />
                جدول الدورة
              </Typography>
              {schedule}
            </Grid>
            {/* 3 */}
            <Grid item>
              <Typography className={classes.headingText} paragraph>
                <span className={classes.littleRedSquare} />
                من يستفيد من الدورة
              </Typography>
              {targetAudience}
            </Grid>
            {/* 4 */}
            <Grid item>
              <Typography className={classes.headingText} paragraph>
                <span className={classes.littleRedSquare} />
                إصدار الشهادة
              </Typography>
              <Typography className={classes.normalText}>
                {certificate}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.sectionSeperator} />
      </Grid>
      <Grid container justify="center" alignItems="center" direction="column">
        <Grid item>
          <Typography className={classes.headingText}>
            لتفاصيل أكثر أو لحجز الدورة
          </Typography>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            component={Link}
            to="/contact"
            className={classes.buttonMore}
          >
            تواصل معنا
          </Button>
        </Grid>
        <div className={classes.sectionSeperatorShadowed} />
      </Grid>
      {/* Other courses */}
      <Grid item container direction="column">
        <Grid item container direction="column">
          {data.allFile.nodes && (
            <>
              <Grid item align="center">
                <Typography className={classes.headingText}>
                  دورات أخرى لدينا
                </Typography>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <ContentPreview
                    data={data}
                    content="courses"
                    direction="column"
                    isButton
                  />
                </Grid>
              </Grid>
            </>
          )}
          <Grid item container justify="center">
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to="/training_courses"
              className={classes.buttonMore}
            >
              الرجوع إلى صفحة الدورات التدريبية
            </Button>
          </Grid>
        </Grid>
        <Grid item className={classes.sectionSeperator} />
      </Grid>
    </Layout>
  )
}

export const query = graphql`
  query GetCourseImage($imageRelativePath: String) {
    file(relativePath: { eq: $imageRelativePath }) {
      childrenImageSharp {
        gatsbyImageData
      }
    }
    redBackground: file(relativePath: { eq: "courseBackgroundColor.jpg" }) {
      childrenImageSharp {
        gatsbyImageData
      }
    }
    allFile(
      filter: {
        relativePath: { in: ["course-1.jpg", "course-2.jpg", "course-3.jpg"] }
      }
    ) {
      nodes {
        courseImage: childrenImageSharp {
          gatsbyImageData(width: 400, height: 250, placeholder: BLURRED)
        }
      }
    }
  }
`

export default Course
